<template>
  <tfoot class="text-xxs">
    <slot></slot>
    <tr>
      <td colspan="100%" class="border-0">Impreso por el usuario {{ usuarioReporte }}</td>
    </tr>
    <tr>
      <td colspan="100%" class="border-0">Consultado el {{ dateStringWithTime }}</td>
    </tr>
  </tfoot>
</template>

<script>
import { useStore } from "vuex";
import moment from "moment";

export default {
  setup() {
    const store = useStore();
    var dateStringWithTime = moment().format("llll");
    const usuarioReporte = store.state.user?.usuario?.nombre;

    return {
      dateStringWithTime,
      usuarioReporte
    };
  }
};
</script>

<style></style>
