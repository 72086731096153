export const columns = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "Avance",
    dataIndex: "avance",
    key: "avance",
    slots: { customRender: "avance" },
    width: 70
  },
  {
    title: "Nombre resumido",
    dataIndex: "denominacion",
    key: "denominacion",
    width: 480,
    slots: { customRender: "denominacion" }
  },
  {
    title: "Grupo",
    dataIndex: "grupo",
    key: "grupo",
    width: 250
  },
  {
    title: "Abreviatura",
    dataIndex: "abreviatura",
    key: "abreviatura",
    width: 100,
    ellipsis: true
  }
];
