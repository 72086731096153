<template>
  <main class="flex-1">
    <PrintHeader />
    <div class="text-center font-bold text-lg my-4">{{ tituloReporte }}</div>
    <slot></slot>
  </main>
</template>

<script>
import moment from "moment";
import PrintHeader from "@/components/PrintHeader.vue";
import { useStore } from "vuex";

export default {
  components: {
    PrintHeader
  },
  props: {
    tituloReporte: {
      type: String,
      default: "SIVINUR"
    }
  },
  setup() {
    const store = useStore();
    var dateStringWithTime = moment().format("llll");
    const usuarioReporte = store.state.user?.usuario?.nombre;

    return {
      dateStringWithTime,
      usuarioReporte
    };
  }
};
</script>

<style scoped>
/* .page {
  width: 216mm;
  min-height: 279mm;
  padding: 20mm;
  margin: 10mm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
} */
/* .subpage {
  padding: 1cm;
  border: 5px red solid;
  height: 239mm;
  outline: 2cm #ffeaea solid;
} */

/* @page {
  size: Letter;
  margin: 0;
}
@media print {
  html,
  body {
    width: 216mm;
    height: 279mm;
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
} */
</style>
