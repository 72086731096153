<template>
  <div style="display: none">
    <div id="printIndicador">
      <PrintContainer tituloReporte="LISTA DE INDICADORES">
        <table class="items-center w-full">
          <thead class="theadprint text-xs p-5">
            <tr>
              <th class="text-center p-3 border border-black">NRO</th>
              <th class="text-center p-3 border border-black">ABREVIATURA</th>
              <th class="text-center p-3 border border-black">NOMBRE</th>
              <th class="text-center p-3 border border-black">GRUPO</th>
              <th class="text-center p-3 border border-black">ESTADO</th>
            </tr>
          </thead>
          <tbody class="text-xs">
            <tr v-for="(indicador, index) in indicadores" :key="index">
              <td class="border border-gray-900 align-middle text-center py-2">
                {{ index + 1 }}
              </td>
              <td class="border border-gray-900 px-2">
                {{ indicador.abreviatura }}
              </td>
              <td class="border border-gray-900 px-2">
                {{ indicador.denominacion }}
              </td>
              <td class="border border-gray-900 px-2">
                {{ indicador.grupo }}
              </td>
              <td class="border border-gray-900 px-2">
                <a-tag :color="indicador.activo === '1' ? 'green' : 'volcano'">
                  {{ indicador.activo === "1" ? "Publicado" : "Ocultado" }}
                </a-tag>
              </td>
            </tr>
          </tbody>
          <PrintFooter />
        </table>
      </PrintContainer>
    </div>
  </div>
</template>

<script>
import PrintContainer from "./PrintContainer.vue";
import PrintFooter from "@/components/PrintFooter.vue";

import { ref } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    PrintContainer,
    PrintFooter
  },
  props: {
    indicadores: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const store = useStore();

    const search = ref(store.getters["indicador/search"]);
    const filter = ref(store.getters["indicador/filter"]);

    return {
      search,
      filter
    };
  }
};
</script>

<style scoped>
table {
  page-break-inside: auto;
  page-break-before: avoid;
}
tr {
  page-break-inside: avoid;
  page-break-after: auto;
}
thead {
  display: table-header-group;
}
tfoot {
  display: table-footer-group;
}
</style>
