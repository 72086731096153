<template>
  <a-page-header
    @back="() => $router.go(-1)"
    title="INDICADOR PARA ESTABLECIMIENTOS"
    class="pt-0 px-0"
  >
    <template #extra>
      <div class="flex flex-wrap items-center gap-3">
        <a-breadcrumb>
          <a-breadcrumb-item><router-link to="/menu">Proyecto</router-link></a-breadcrumb-item>
          <a-breadcrumb-item>Indicador para establecimientos</a-breadcrumb-item>
        </a-breadcrumb>
      </div>
    </template>
  </a-page-header>

  <div class="flex flex-wrap mb-4">
    <fieldset class="w-full mt-3 px-8 md:mt-0 lg:w-auto">
      <a-form
        layout="horizontal"
        :model="formState"
        :rules="rules"
        ref="formRefFilter"
        class="md:flex gap-4 items-center"
      >
        <div class="mb-2">
          <a-form-item class="mb-0" name="search">
            <a-input v-model:value="formState.search" placeholder="Buscar" />
          </a-form-item>
        </div>

        <div class="flex">
          <button @click="applyFilters" type="submit" class="text-center px-3">
            <div class="text-3xl text-blue-400">
              <SearchOutlined />
            </div>
            <p>Buscar</p>
          </button>

          <button
            @click="cleanFilters"
            type="button"
            class="text-center px-3"
            :disabled="isDisabled"
          >
            <div
              class="text-3xl"
              :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-purple-500']"
            >
              <ClearOutlined />
            </div>
            <p>Limpiar filtros</p>
          </button>
        </div>
      </a-form>
    </fieldset>

    <fieldset class="flex gap-4 w-full mt-3 px-8 md:mt-0 lg:w-auto">
      <button @click="applyExportar" class="text-center">
        <div class="text-3xl text-green-700">
          <FileExcelOutlined />
        </div>
        <p>Exportar</p>
      </button>

      <button v-print="objPrintIndicador" class="text-center">
        <div class="text-3xl text-red-400">
          <FilePdfOutlined />
        </div>
        <p>Descargar</p>
      </button>
    </fieldset>
  </div>

  <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
    <a-table
      :columns="columns"
      :data-source="indicadores"
      size="small"
      :pagination="false"
      rowKey="idindicador"
      :loading="isLoading"
      :rowClassName="
        (record, index) =>
          index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
      "
    >
      <template #rangepages="{ index }">
        <p class="text-center">{{ (currentPage - 1) * 10 + index + 1 }}</p>
      </template>

      <template #avance="{ text: avance }"> {{ avance }} % </template>

      <template #denominacion="{ record }">
        <a-tooltip>
          <template #title>Ver resumen de indicador </template>
          <router-link :to="'/indicador-establecimiento/' + record.idindicador">
            {{ record.denominacion }}
          </router-link>
        </a-tooltip>
      </template>

      <template #activo="{ text: activo }">
        <span>
          <a-tag :color="activo === '1' ? 'green' : 'volcano'">
            {{ activo === "1" ? "Publicado" : "Ocultado" }}
          </a-tag>
        </span>
      </template>
    </a-table>
  </div>

  <footer class="flex flex-col md:flex-row items-center justify-between pb-6 pt-5 border-t-2">
    <div class="flex">
      <a-pagination v-model:current="currentPage" v-model:pageSize="pageSize" :total="totalDocs" />

      <button class="ml-4" @click="reloadFetch">
        <ReloadOutlined />
      </button>
    </div>
    <p class="py-3 md:py-0">Total de registros: {{ totalDocs }}</p>
  </footer>

  <PrintIndicador :indicadores="indicadoresToPrint" />
</template>

<script>
import { columns } from "./utilsIndicadorEstablecimiento";
import IndicadorApi from "@/api/indicador";
import UsuariosApi from "@/api/user";
import ApiDescargas from "@/api/descargas";
import PrintIndicador from "@/print/PrintIndicador.vue";
import { calculaPorcentaje } from "@/utils";
import { onMounted, ref, watch, reactive, toRaw } from "vue";
import { useStore } from "vuex";
import {
  SearchOutlined,
  ClearOutlined,
  ReloadOutlined,
  FileExcelOutlined,
  FilePdfOutlined
} from "@ant-design/icons-vue";

export default {
  components: {
    PrintIndicador,
    SearchOutlined,
    ClearOutlined,
    ReloadOutlined,
    FileExcelOutlined,
    FilePdfOutlined
  },
  setup() {
    const store = useStore();
    const pageSize = ref(10);
    const currentPage = ref(1);
    const formRefFilter = ref();
    const isLoading = ref(false);
    const isDisabled = ref(true);
    const indicadores = ref([]);
    const totalDocs = ref(0);
    const indicadoresToPrint = ref([]);

    const { establecimiento } = store.state.user.usuario;

    const formState = reactive({
      search: store.getters["indicador/search"]
    });

    const objPrintIndicador = reactive({
      id: "printIndicador",
      popTitle: "SIVINUR | RESUMEN DE INDICADOR"
    });

    const fetchData = (currentPage = 1) => {
      const search = store.getters["indicador/search"];

      isLoading.value = true;
      IndicadorApi.getAll({ currentPage, search, filter: "1" })
        .then((response) => {
          const indicadores_formated = response.data.docs.map((ind) => {
            const avance = calculaPorcentaje(ind.num, ind.den);

            return { ...ind, avance };
          });

          indicadores.value = indicadores_formated;
          totalDocs.value = response.data.totalDocs;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    onMounted(() => {
      UsuariosApi.userLogAdd("Proyecto", "Indicador establecimiento", establecimiento);
      fetchData();
      fetchIndicadoresToPrint();
    });

    watch(currentPage, () => {
      fetchData(currentPage.value);
    });

    watch(store.state.indicador, () => {
      fetchIndicadoresToPrint();
    });

    const rules = {
      search: [
        {
          max: 50,
          message: "La cantidad máxima de caracteres es de 50",
          trigger: "blur"
        }
      ]
    };

    const fetchIndicadoresToPrint = () => {
      const search = store.getters["indicador/search"];

      IndicadorApi.getAll({ search, filter: "1" })
        .then((response) => {
          indicadoresToPrint.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    const applyFilters = () => {
      isDisabled.value = false;
      formRefFilter.value
        .validate()
        .then(async () => {
          const { search } = toRaw(formState);
          store.dispatch("indicador/setSearch", search);
          currentPage.value = 1;
          fetchData(currentPage.value);
        })
        .catch((err) => console.log(err));
    };

    const cleanFilters = () => {
      isDisabled.value = true;
      formState.filter = "";
      formState.search = "";
      store.dispatch("indicador/cleanFilters");
      currentPage.value = 1;
      fetchData(currentPage.value);
    };

    const applyExportar = () => {
      const search = store.getters["indicador/search"];

      ApiDescargas.getReporteIndicador({ search, filter: "1" }, "indicador", "REPORTE INDICADOR")
        .then(() => {})
        .catch((err) => console.log(err));
    };

    return {
      columns,
      isLoading,
      indicadores,
      totalDocs,
      currentPage,
      formState,
      rules,
      isDisabled,
      applyFilters,
      cleanFilters,
      formRefFilter,
      pageSize,
      applyExportar,
      objPrintIndicador,
      indicadoresToPrint
    };
  }
};
</script>

<style></style>
